import React, { useEffect } from 'react';
import { SERVER_PROCESSING_ERROR, SMTH_WENT_WRONG, UNKNOWN_SERVER_ERROR } from '@constants';
import { FullStory } from '@fullstory/browser';

import { updateOrganizationDataInLocalStorage } from '@api';
import invalidateCache from '@api/invalidateCache';
import {
  useFetchOrganizationIndustry,
  usePostOrganization,
} from '@api/organizations/organizations';
import { useFetchUsersEmployeeRoles } from '@api/users';
import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import useForm from '@components/Form/useForm';
import { Column, Row } from '@components/Grid';
import Input from '@components/Input/Input.v1';
import BaseModalHeader from '@components/Modal/BaseModal/BaseModalHeader';
import Text from '@components/Text';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Select from '@components/UI/Select.v1/Select';
import { Option, SelectValue } from '@components/UI/Select.v1/types';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName, SegmentTrackPageName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';

import FormLabel from '../FormLabel';

const otherOption: Option = {
  key: 'other',
  text: 'Other (Please Specify)',
  value: 'other',
};

const USE_CASE_OPTIONS: Option[] = [
  {
    text: 'Data discovery/catalog for my data team',
    value: 'team_discovery',
  },
  {
    text: 'Data discovery/catalog to support the rest of the organization (ops, product, marketing, etc)',
    value: 'org_discovery',
  },
  {
    text: 'Data migration',
    value: 'data_migration',
  },
  { text: 'Just looking around', value: 'looking_around' },
  { text: 'Other (Please Specify)', value: 'other' },
];

const HEARD_ABOUT_OPTIONS: Option[] = [
  {
    text: 'Search Engine (Google, etc)',
    value: 'search_engine',
  },
  {
    text: 'Social (Twitter, LinkedIn, etc)',
    value: 'social',
  },
  {
    text: 'Blog post / Podcast',
    value: 'blog_post_or_podcast',
  },
  { text: 'Email from Select Star', value: 'selectstar_email' },
  {
    text: 'Conference / Tradeshow',
    value: 'conference_or_tradeshow',
  },
  {
    text: 'Ads',
    value: 'ads',
  },
  {
    text: 'Heard from Friends / Network',
    value: 'friends_or_network',
  },
  { text: 'Other (Please Specify)', value: 'other' },
];

interface OrganizationOnboardingForm {
  allow_automatic_login: boolean;
  data_team_size: string;
  heard_about: SelectValue;
  industry: SelectValue;
  industry_other?: string;
  name: string;
  role_type: SelectValue;
  role_type_other?: string;
  team: string;
  use_case: SelectValue;
  use_case_other?: string;
}

interface OrganizationOnboardingProps {
  onSuccess: () => void;
}

const OrganizationOnboarding: React.FC<OrganizationOnboardingProps> = ({ onSuccess }) => {
  const segment = useSegmentContext();
  const { user } = useUserContext();
  const { data, isLoading: isLoadingIndustries } = useFetchOrganizationIndustry();
  const { data: employeeRoles, isLoading: isLoadingRoles } = useFetchUsersEmployeeRoles();

  const industryOptions: Option[] | undefined = data?.results.map((industry) => {
    return {
      key: industry.industryName,
      text: industry.industryName,
      value: industry.industryName,
    };
  });
  industryOptions?.push(otherOption);

  const roleOptions: Option[] | undefined = employeeRoles?.results.map((role) => {
    return {
      key: role.name,
      text: role.name,
      value: role.name,
    };
  });
  roleOptions?.push(otherOption);

  const {
    error,
    isLoading,
    mutate: createOrganization,
  } = usePostOrganization({
    onSuccess: (d) => {
      updateOrganizationDataInLocalStorage(d);
      invalidateCache((keys) => [keys.organizationUser.all, keys.organizations.all]);

      if (user) {
        segment?.identify(user?.guid, {
          isCreator: true,
        });
      }

      if (!process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
        FullStory('setProperties', {
          properties: { email: user?.email, isCreator: true },
          type: 'user',
        });
      }

      segment?.track(SegmentTrackEventName.SetupAccountNextButtonClicked, values);
      onSuccess();
    },
  });

  const { handleChange, handleSubmit, setValues, touched, values } =
    useForm<OrganizationOnboardingForm>({
      initialValues: {
        allow_automatic_login: false,
        data_team_size: '',
        heard_about: undefined,
        industry: undefined,
        industry_other: '',
        name: '',
        role_type: undefined,
        role_type_other: '',
        team: '',
        use_case: undefined,
        use_case_other: '',
      },
      onSubmit: (val) => {
        const industryValue = val.industry?.[0]?.value;
        const roleTypeValue = val.role_type?.[0]?.value;
        const useCaseValue = val.use_case?.[0]?.value;
        const heardAboutValue = val.heard_about?.[0]?.value;

        const isValIndustryOther = industryValue === 'other';
        const isValRoleOther = roleTypeValue === 'other';
        const isValUseCaseOther = useCaseValue === 'other';

        const params = {
          ...val,
          heard_about: heardAboutValue ?? '',
          industry: isValIndustryOther ? val.industry_other : industryValue,
          role_type: isValRoleOther ? val.role_type_other : roleTypeValue,
          use_case: isValUseCaseOther ? val.use_case_other : useCaseValue,
        };
        createOrganization(params);
      },
    });

  const isNextButtonDisabled = React.useMemo(
    () =>
      !values.name ||
      !values.data_team_size ||
      !values.role_type ||
      !values.team ||
      !values.use_case ||
      !values.heard_about ||
      !values.industry,
    [values],
  );

  const handleSelectChange = (newValue: SelectValue, field: keyof OrganizationOnboardingForm) => {
    handleChange({ target: { name: field, value: newValue as ChangeEvent['target']['value'] } });
  };

  const userName = `, ${user?.firstName}` || '';
  const isIndustryOther = values.industry?.[0]?.value === 'other';
  const isUseCaseOther = values.use_case?.[0]?.value === 'other';
  const isRoleTypeOther = values.role_type?.[0]?.value === 'other';

  useEffect(() => {
    segment?.page(SegmentTrackPageName.SetupAccount);
  }, []);

  return (
    <Box
      backgroundColor="white"
      borderRadius="8px"
      m="auto"
      maxWidth="820px"
      p={5}
      position="relative"
    >
      <BaseModalHeader padding={0} title={`Welcome to Select Star${userName}!`} />
      <form onSubmit={handleSubmit}>
        <Box>
          <Box mt={1}>
            <Text fontSize="16px" fontWeight="medium" p={0.5} pb={1}>
              1. Let’s set up your organization account.
            </Text>
          </Box>
          <Box compDisplay="flex" flexDirection="column" gap={2} mt={0.5} pl={3}>
            <Row alignItems="first baseline" space={0}>
              <Column hideDown={['xs', 'sm']} md={5} sm={12}>
                <FormLabel>Organization name</FormLabel>
              </Column>
              <Column md={7} sm={12}>
                <Input
                  aria-label="name"
                  compWidth="19rem"
                  error={!!error?.data?.name && !touched.name}
                  helperText={!touched.name && error?.data.name?.[0]}
                  name="name"
                  onChange={handleChange}
                  placeholder="Enter your organization name here"
                  type="text"
                  value={values.name}
                />
              </Column>
            </Row>
            <Row alignItems="first baseline" space={0}>
              <Column sm={12}>
                <InputLabel alignItems="flex-start" fontSize="body1">
                  <Checkbox
                    checked={values.allow_automatic_login}
                    error={!!error?.data?.allow_automatic_login && !touched.allow_automatic_login}
                    mt={0.2}
                    onChange={() =>
                      setValues((prev) => ({
                        ...prev,
                        allow_automatic_login: !prev.allow_automatic_login,
                      }))
                    }
                  />
                  <Box compWidth="80%">
                    <Text as="p" fontSize="14px" fontWeight="medium" m={0} p={0}>
                      Allow new users with the same domain name to join my Select Star organization
                    </Text>
                    <Text as="p" fontSize="14px" fontWeight="regular">
                      (If this is false, new users will need an invitation to join your
                      organization)
                    </Text>
                    {!touched.allow_automatic_login && error?.data.allow_automatic_login && (
                      <Alert mt={1} type="error">
                        {error?.data.allow_automatic_login[0]}
                      </Alert>
                    )}
                  </Box>
                </InputLabel>
              </Column>
            </Row>
          </Box>
          <Box mt={2}>
            <Text fontSize="16px" fontWeight="medium" p={0.5} pb={1}>
              2. Tell us more about your company.
            </Text>
          </Box>
          <Box compDisplay="flex" flexDirection="column" gap={2} pl={3}>
            <Row alignItems="first baseline" space={0}>
              <Column hideDown={['xs', 'sm']} md={5} sm={12}>
                <FormLabel>Industry</FormLabel>
              </Column>
              <Column md={7} sm={12}>
                <Select
                  compWidth="19rem"
                  error={!!error?.data?.industry && !isIndustryOther && !touched.industry}
                  helperText={
                    !touched.industry &&
                    error?.data.industry &&
                    !isIndustryOther &&
                    error?.data?.industry?.[0]
                  }
                  isLoading={isLoadingIndustries}
                  label="industry"
                  maxOptionsVisible={5}
                  onChange={(newValue) => handleSelectChange(newValue, 'industry')}
                  options={industryOptions}
                  placeholder="Choose Industry"
                  value={values.industry}
                />
              </Column>
            </Row>
            {isIndustryOther && (
              <Row alignItems="first baseline" space={0}>
                <Column hideDown={['xs', 'sm']} md={5} sm={12} />
                <Column md={7} sm={12}>
                  <Input
                    aria-label="industry_other"
                    compWidth="19rem"
                    error={!!error?.data?.industry && isIndustryOther && !touched.industry_other}
                    helperText={
                      !touched.industry_other && error?.data.industry && error?.data?.industry?.[0]
                    }
                    name="industry_other"
                    onChange={handleChange}
                    placeholder="Enter your industry here"
                    type="text"
                    value={values.industry_other}
                  />
                </Column>
              </Row>
            )}
            <Row alignItems="first baseline" space={0}>
              <Column hideDown={['xs', 'sm']} md={5} sm={12}>
                <FormLabel>Your role in the company</FormLabel>
              </Column>
              <Column md={7} sm={12}>
                <Select
                  compWidth="19rem"
                  error={!!error?.data?.role_type && !isRoleTypeOther && !touched.role_type}
                  helperText={
                    !touched.role_type &&
                    error?.data.role_type &&
                    !isRoleTypeOther &&
                    error?.data.role_type[0]
                  }
                  isLoading={isLoadingRoles}
                  label="role"
                  maxOptionsVisible={5}
                  onChange={(newValue) => handleSelectChange(newValue, 'role_type')}
                  options={roleOptions}
                  placeholder="Choose Role"
                  value={values.role_type}
                />
              </Column>
            </Row>
            {isRoleTypeOther && (
              <Row alignItems="first baseline" space={0}>
                <Column hideDown={['xs', 'sm']} md={5} sm={12} />
                <Column md={7} sm={12}>
                  <Input
                    aria-label="role_type_other"
                    compWidth="19rem"
                    error={!!error?.data?.role_type && isRoleTypeOther && !touched.role_type_other}
                    helperText={
                      !touched.role_type_other && error?.data.role_type && error?.data.role_type[0]
                    }
                    name="role_type_other"
                    onChange={handleChange}
                    placeholder="Enter your role here"
                    type="text"
                    value={values.role_type_other}
                  />
                </Column>
              </Row>
            )}
            <Row alignItems="first baseline" space={0}>
              <Column hideDown={['xs', 'sm']} md={5} sm={12}>
                <FormLabel>Your team name</FormLabel>
              </Column>
              <Column md={7} sm={12}>
                <Input
                  aria-label="team"
                  compWidth="19rem"
                  error={!!error?.data?.team && !touched.team}
                  helperText={!touched.team && error?.data.team && error?.data.team[0]}
                  name="team"
                  onChange={handleChange}
                  placeholder="Data"
                  type="text"
                  value={values.team}
                />
              </Column>
            </Row>
            <Row alignItems="first baseline" space={0}>
              <Column hideDown={['xs', 'sm']} md={5} sm={12}>
                <FormLabel>How big is your data team?</FormLabel>
                <FormLabel>(# of data analysts, engineers, etc)</FormLabel>
              </Column>
              <Column md={7} sm={12}>
                <Input
                  aria-label="data_team_size"
                  compWidth="19rem"
                  error={!!error?.data?.data_team_size && !touched.data_team_size}
                  helperText={
                    !touched.data_team_size &&
                    error?.data.data_team_size &&
                    error?.data.data_team_size[0]
                  }
                  min={0}
                  name="data_team_size"
                  onChange={handleChange}
                  placeholder="Data Team Size"
                  type="number"
                  value={values.data_team_size}
                />
              </Column>
            </Row>
            <Row alignItems="first baseline" space={0}>
              <Column hideDown={['xs', 'sm']} md={5} sm={12}>
                <FormLabel>How did you find out about Select Star?</FormLabel>
              </Column>
              <Column md={7} sm={12}>
                <Select
                  compWidth="19rem"
                  error={!!error?.data?.heard_about && !touched.heard_about}
                  helperText={
                    !touched.heard_about && error?.data.heard_about && error?.data.heard_about[0]
                  }
                  label="heard_about"
                  maxOptionsVisible={5}
                  onChange={(newValue) => handleSelectChange(newValue, 'heard_about')}
                  options={HEARD_ABOUT_OPTIONS}
                  placeholder="Choose One"
                  value={values.heard_about}
                />
              </Column>
            </Row>
            <Row alignItems="flex-start" space={0}>
              <Column hideDown={['xs', 'sm']} md={5} sm={12}>
                <FormLabel>What’s your main purpose of trying out Select Star?</FormLabel>
              </Column>
              <Column md={7} sm={12}>
                <Select
                  compWidth="19rem"
                  error={!!error?.data?.use_case && !isUseCaseOther && !touched.use_case}
                  helperText={
                    !touched.use_case &&
                    error?.data.use_case &&
                    !isUseCaseOther &&
                    error?.data.use_case[0]
                  }
                  label="use_case"
                  maxOptionsVisible={5}
                  onChange={(newValue) => handleSelectChange(newValue, 'use_case')}
                  options={USE_CASE_OPTIONS}
                  placeholder="Choose One"
                  value={values.use_case}
                />
              </Column>
            </Row>
            {isUseCaseOther && (
              <Row alignItems="first baseline" space={0}>
                <Column hideDown={['xs', 'sm']} md={5} sm={12} />
                <Column md={7} sm={12}>
                  <Input
                    aria-label="use_case_other"
                    compWidth="19rem"
                    error={!!error?.data?.use_case && isUseCaseOther && !touched.use_case_other}
                    helperText={
                      !touched.use_case_other && error?.data.use_case && error?.data.use_case[0]
                    }
                    name="use_case_other"
                    onChange={handleChange}
                    placeholder="Enter your use case here"
                    type="text"
                    value={values.use_case_other}
                  />
                </Column>
              </Row>
            )}
            <Box compWidth="85%">
              {error && error?.status === 500 && (
                <Alert title={SMTH_WENT_WRONG} type="error">
                  {SERVER_PROCESSING_ERROR} {error?.data?.detail || UNKNOWN_SERVER_ERROR}
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
        <Box compDisplay="flex" justifyContent="flex-end" mt={1}>
          <NextStepButton disabled={isLoading || isNextButtonDisabled} />
        </Box>
      </form>
    </Box>
  );
};

export default OrganizationOnboarding;
