import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useFetchColumns, usePatchColumn } from '@api/columns';
import { ColumnModel } from '@api/columns/ColumnModel';
import { useDeleteForeignKey, usePostForeignKeys } from '@api/foreignKeys';
import invalidateCache from '@api/invalidateCache';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import { getColumnOptions } from '@components/Dropdown/helpers';
import SelectedSearchItemPill from '@components/Modal/CreateMetricModal/SelectedSearchItemPill';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import Modal, { ModalFooter, ModalHeader } from '@components/UI/Modal';
import Select from '@components/UI/Select.v1/Select';
import type { Option, SelectValue } from '@components/UI/Select.v1/types';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

// Small helper function to get correct column names
const getName = (fullName: string) => {
  const splitName = fullName?.split('.');
  return splitName ? `${splitName.slice(Math.max(splitName.length - 3, 1)).join('.')}` : '';
};

const defaultColumnConfig: Filter.FilterOptions = {
  order: '-popularity',
  page_size: 50,
  query: stripSpaces(`{
    guid,
    name,
    table{
      name,
      schema{
        name
      }
    },
    data_type,
    data_types
  }`),
};

interface SelectedColumn extends Option {
  fkGuid?: string;
}

interface ReferenceDropdownProps {
  dsGuid?: string;
  handleChange: (value: SelectValue) => void;
  selectedColumns?: SelectValue;
}

const ReferenceDropdown: React.FC<ReferenceDropdownProps> = ({
  dsGuid,
  handleChange,
  selectedColumns,
}) => {
  const [search, setSearch] = useState<string | undefined>('');
  const { data: columns, isLoading } = useFetchColumns({
    keepPreviousData: true,
    params: {
      ...defaultColumnConfig,
      datasources: dsGuid,
      search_name: search,
    },
  });

  const handleSearchChange = useDebouncedCallback(setSearch, 500);

  const options = getColumnOptions(columns?.results);
  const filtered = selectedColumns?.length
    ? options.filter(({ value }) => !selectedColumns?.some((el) => el.value === value))
    : options;

  const handleOnchange = (value: SelectValue) => {
    setSearch('');
    handleChange(value);
  };

  return (
    <Select
      isLoading={isLoading}
      onChange={handleOnchange}
      onSearchValueChange={handleSearchChange}
      options={filtered}
      placeholder="Add Reference..."
      value={[]}
    />
  );
};

export interface EditPkFkModalProps {
  column: ColumnModel;
  dsGuid?: string;
  onClose?: () => void;
}

const EditPkFkModal: React.FC<EditPkFkModalProps> = ({ column, dsGuid, onClose }) => {
  const [isPk, setIsPk] = useState<boolean>(column.isPrimaryKey);
  const [isFk, setIsFk] = useState<boolean>(column.isForeignKey);
  const [selectedColumns, setSelectedColumns] = useState<SelectedColumn[]>(
    column.foreignKeys?.map((fk) => {
      const col = fk.targetColumns[0];
      const fullName = col.fullName ? getName(col.fullName) : col.name;
      return {
        fkGuid: fk.guid,
        icon: col.dataTypes?.icons.dataType!,
        original: {
          dataTypes: col.dataTypes,
          fullName,
          guid: col.guid,
        },
        text: fullName,
        value: col.guid,
      };
    }) ?? [],
  );

  const [validationError, setValidationError] = useState(false);

  const { isLoading: pkLoading, mutate: mutatePk } = usePatchColumn(column.guid, {
    onError: () => {
      renderErrorToast('Operation Failed');
    },
    onSuccess: () => {
      renderInfoToast('Primary Key Updated');
      invalidateCache((keys) => [keys.columns.all]);
      onClose?.();
    },
  });

  const { isLoading: postFKisLoading, mutate: postFK } = usePostForeignKeys({
    onError: () => {
      renderErrorToast('Operation Failed');
    },
    onSuccess: () => {
      renderInfoToast('Foreign Key Updated');
      invalidateCache((keys) => [keys.columns.all]);
      onClose?.();
    },
  });

  const { isLoading: deleteFkIsLoading, mutate: deleteFK } = useDeleteForeignKey({
    onError: () => {
      onClose?.();
      renderErrorToast('Operation Failed');
    },
    onSuccess: (_, variables) => {
      renderInfoToast('Foreign Key Updated');
      setSelectedColumns((previous) =>
        previous.filter(({ fkGuid }) => fkGuid !== variables.fkGuid),
      );
      invalidateCache((keys) => [keys.columns.all]);
    },
  });

  const loading = pkLoading || postFKisLoading || deleteFkIsLoading;

  const deleteSingleFk = ({ fkGuid, guid }: { fkGuid?: string; guid?: string }) => {
    if (fkGuid) {
      deleteFK({ fkGuid, httpClientUrl: `/foreign-keys/${fkGuid}/` });
    } else {
      setSelectedColumns((previous) => previous.filter((el) => el.original.guid !== guid));
    }
  };

  const deleteAllFk = () => {
    postFK({
      items: [
        {
          source_column: column.guid,
          target_column: null,
        },
      ],
    });
  };

  const addFk = () => {
    postFK({
      items: selectedColumns?.map((el) => ({
        source_column: column.guid,
        target_column: el.original.guid,
      })),
    });
  };

  const editPk = () => {
    mutatePk({ is_primary_key: isPk });
  };

  const handleSubmit = () => {
    if (isFk && selectedColumns?.length === 0) {
      setValidationError(true);
    } else if (!loading && !validationError) {
      if (column.isPrimaryKey !== isPk) {
        editPk();
      }
      if (!isFk && column.isForeignKey) {
        deleteAllFk();
      } else {
        addFk();
      }
    }
  };

  const handleChange = (value: SelectValue) => {
    const option = value?.[0];
    if (option) {
      setSelectedColumns((prev) => [...prev, option]);
      setValidationError(false);
    }
  };
  const breadcrumbs = column.breadcrumbLabelList;
  const isLoading = pkLoading || postFKisLoading || deleteFkIsLoading;

  return (
    <Modal onClose={onClose} overflow={isLoading ? 'hidden' : 'unset'} size="tiny">
      <ModalHeader onClose={onClose} title="Add PK/FK Label" />
      <Box p={2}>
        {isLoading && <CircularLoader compDisplay="block" compSize={6} cover />}
        <Box alignItems="center" color="gray.700" compDisplay="flex" gap={0.5} mb={2} noDefault>
          <Icon name={column.dataTypes?.icons.dataType!} />
          <Breadcrumbs
            clickable={false}
            color="inherit"
            fontSize="inherit"
            fontWeight="unset"
            items={breadcrumbs.slice(breadcrumbs.length - 2, breadcrumbs.length)}
            showTrailingSlash={false}
          />
        </Box>
        <Box compDisplay="flex" flexDirection="column" gap={1} noDefault>
          <InputLabel>
            <Checkbox
              checked={isPk}
              onChange={() => {
                setValidationError(false);
                setIsPk((prev) => !prev);
              }}
            />
            <Button as="span" variant="constraint">
              <Icon name="key" size="12px" />
              PK
            </Button>
          </InputLabel>
          <InputLabel>
            <Checkbox
              checked={isFk}
              onChange={() => {
                setValidationError(false);
                setIsFk((prev) => !prev);
              }}
            />
            <Button as="span" variant="constraint">
              <Icon name="key-gray" size="12px" />
              FK
            </Button>
          </InputLabel>
          {isFk && (
            <Box compDisplay="flex" flex={1} flexDirection="column" gap={1} ml={3} noDefault>
              <ReferenceDropdown
                dsGuid={dsGuid}
                handleChange={handleChange}
                selectedColumns={selectedColumns}
              />
              {selectedColumns.map((el) => (
                <SelectedSearchItemPill
                  key={el.value}
                  hideDataSourceIcon
                  onRemove={() => deleteSingleFk({ fkGuid: el.fkGuid, guid: el.original.guid })}
                  selectedItem={el.original}
                />
              ))}
              {validationError && (
                <Alert title="Required" type="error">
                  FK reference is required
                </Alert>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <ModalFooter>
        <Button disabled={loading || validationError} onClick={handleSubmit} type="submit">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditPkFkModal;
