import React, { Dispatch, SetStateAction } from 'react';
import iconsConfigMap from '@configs/icons/config';
import { DEFAULT_DOCS_URL } from '@constants';

import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import Dropdown from '@components/Dropdown';
import Link from '@components/Link';
import Icon from '@components/UI/Icon';
import { ModalFooter } from '@components/UI/Modal';
import { useUserContext } from '@context/User';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import { DataSourceOptions } from '@models/DataSourceOptions';

import { StyledLabel } from '../../DataSourceSetup.styles';

import DataSourceAddStepError from './DataSourceAddStepError';
import forms from './forms';

const Empty = () => null;

interface DataSourceAddStepProps {
  dataSource?: DataSourceModel;
  dataType: DataSourceTypesType;
  onDataSourceAdded: (dataSource: DataSourceModel) => void;
  setDataType: Dispatch<SetStateAction<DataSourceTypesType>>;
}

const DataSourceAddStep: React.FC<DataSourceAddStepProps> = ({
  dataSource,
  dataType,
  onDataSourceAdded,
  setDataType,
}) => {
  const { settings } = useUserContext();
  const DataSourceForm =
    (settings?.useDbtMultiProject && dataType === 'dbt' ? forms.dbtV1 : forms?.[dataType]) ?? Empty;

  const filteredDataSourceOptions = DataSourceOptions.filter((option) => {
    if (!settings?.useMssql && option.value === 'mssql') return false;
    if (!settings?.useMysql && option.value === 'mysql') return false;
    if (!settings?.useOracle && option.value === 'oracle') return false;
    if (!settings?.useSalesforce && option.value === 'salesforce') return false;
    if (option.value === 'monte_carlo') return false;
    return true;
  });

  return (
    <DataSourceForm
      dataSource={dataSource}
      dataType={dataType}
      name={dataType ? DataSourceOptions.find((ds) => ds.value === dataType)?.text : ''}
      onSuccess={onDataSourceAdded}
      renderBefore={({ error }) => (
        <>
          <Box gridColumn="1/3">
            <Alert>
              We recommend creating a service user account for Select Star in your DWH or BI. See
              the instructions in our&nbsp;
              <Link
                fontSize="inherit"
                href={`${DEFAULT_DOCS_URL}/integrations`}
                rel="noopener noreferrer"
                target="_blank"
                underline
              >
                documentation
              </Link>
              .
            </Alert>
          </Box>
          <StyledLabel as="div">
            Source Type
            <Dropdown
              disabled={!!dataSource}
              error={error?.data?.type}
              onChange={(_, d) => setDataType(d.value as DataSourceTypesType)}
              options={filteredDataSourceOptions.map((option) => ({
                ...option,
                image: (
                  <Icon
                    compDisplay="inline-block"
                    mr={0.5}
                    name={iconsConfigMap[option.value]?.default || 'question'}
                    size="16px"
                  />
                ),
              }))}
              placeholder="Source Type"
              selection
              value={dataType}
            />
          </StyledLabel>
        </>
      )}
    >
      {({ error, loading }) => (
        <>
          <DataSourceAddStepError error={error} pb={2} />
          <ModalFooter>
            <NextStepButton disabled={loading} text="Save" />
          </ModalFooter>
        </>
      )}
    </DataSourceForm>
  );
};

export default DataSourceAddStep;
