import React, { useMemo } from 'react';

import { useFetchDbtTests, useFetchDbtWarehouseLink } from '@api/dbt';
import { useFetchMonitors } from '@api/monitors';
import { GetMonitorsListQueryParams } from '@api/openapi.generated';
import Box from '@components/Box';
import StatusBadge from '@components/StatusBadge';
import { useUserContext } from '@context/User';
import { Filter } from '@utils';

import DataQualityTabContent from './DataQualityTabContent';
import { FILTER_CONFIG as DBT_TESTS_FILTER_CONFIG } from './DataQualityTabContent/MonitorsDbtTestsTable';
import { FILTER_CONFIG as MONITORS_FILTER_CONFIG } from './DataQualityTabContent/MonitorsTable';

const useDataQualityTab = (guid: string) => {
  const { dataSources, organization } = useUserContext();

  const useDataQuality = organization?.settings?.useDataQualityTab;

  let hadMonteIntegration = false;
  let hadDbtTests = false;
  Object.values(dataSources ?? {})?.forEach(({ settings, type }) => {
    if (type === 'monte_carlo') {
      hadMonteIntegration = true;
    }
    if (type === 'dbt' && settings?.hasDbtTests) {
      hadDbtTests = true;
    }
  });
  const hadDataQualityIntegration = hadMonteIntegration || hadDbtTests;

  const { filter: monitorsFilter } = Filter.useUpdateFilters(
    MONITORS_FILTER_CONFIG.defaultFiltersConfig,
    MONITORS_FILTER_CONFIG.searchConfig,
    MONITORS_FILTER_CONFIG.sortConfig,
    MONITORS_FILTER_CONFIG.defaultOrder,
  );
  const monitorsFiltersWithParams = Filter.setParams(monitorsFilter);

  const { data: monitorsData } = useFetchMonitors({
    enabled: useDataQuality && hadMonteIntegration,
    params: {
      ...monitorsFiltersWithParams,
      order: monitorsFiltersWithParams.order as GetMonitorsListQueryParams['order'],
      tables: guid,
    },
  });

  const { data: dbtWarehouseLinkData } = useFetchDbtWarehouseLink(guid, {
    enabled: useDataQuality && Boolean(guid),
    params: {
      types: 'table,view,incremental,seed,dynamic_table',
    },
  });

  const dbtModelGuid = dbtWarehouseLinkData?.results[0]?.dbtModel?.guid;

  const { filter: dbtTestsFilter } = Filter.useUpdateFilters(
    DBT_TESTS_FILTER_CONFIG.defaultFiltersConfig,
    DBT_TESTS_FILTER_CONFIG.searchConfig,
    DBT_TESTS_FILTER_CONFIG.sortConfig,
    DBT_TESTS_FILTER_CONFIG.defaultOrder,
  );

  const { data: dbtTestsData } = useFetchDbtTests({
    enabled: useDataQuality && Boolean(dbtModelGuid),
    params: {
      ...Filter.setParams(dbtTestsFilter),
      table: dbtModelGuid,
    },
  });

  const dataQualityCount = useMemo(() => {
    if (
      (!hadMonteIntegration || monitorsData?.status_counts) &&
      (dbtWarehouseLinkData?.count === 0 || dbtTestsData?.status_counts)
    ) {
      return {
        error:
          (dbtTestsData?.status_counts?.error ?? 0) + (monitorsData?.status_counts?.error ?? 0),
        success:
          (dbtTestsData?.status_counts?.success ?? 0) + (monitorsData?.status_counts?.success ?? 0),
        warning:
          (dbtTestsData?.status_counts?.warning ?? 0) + (monitorsData?.status_counts?.warning ?? 0),
      };
    }

    return undefined;
  }, [
    dbtTestsData?.status_counts,
    dbtWarehouseLinkData?.count,
    hadMonteIntegration,
    monitorsData?.status_counts,
  ]);

  const dataQualityTabConfig = useMemo(() => {
    if (!hadDataQualityIntegration) {
      return null;
    }

    return {
      component: () => (
        <DataQualityTabContent
          dbtModelGuid={dbtModelGuid}
          initialCounts={{
            dbt: dbtTestsData?.status_counts,
            monteCarlo: monitorsData?.status_counts,
          }}
          tableGuid={guid}
          tabs={{ dbt: hadDbtTests, monteCarlo: hadMonteIntegration }}
        />
      ),
      contentOffset: 2,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          Data Quality
          <StatusBadge counts={dataQualityCount} />
        </Box>
      ),
      route: '/data-quality',
    };
  }, [
    dataQualityCount,
    dbtModelGuid,
    dbtTestsData?.status_counts,
    guid,
    hadDataQualityIntegration,
    hadDbtTests,
    hadMonteIntegration,
    monitorsData?.status_counts,
  ]);

  return {
    dataQualityTabConfig,
    hadDbtTests,
    hadMonteIntegration,
  };
};

export default useDataQualityTab;
