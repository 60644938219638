import React from 'react';

import { useFetchDbtProjects } from '@api/dbt';
import { DbtTypes } from '@api/dbt/types';
import Alert from '@components/Alert';
import Box from '@components/Box';
import useForm from '@components/Form/useForm';
import type { FormValues } from '@components/Forms/CreateDbtProjectForm';
import CreateDbtProjectForm, { FieldKey } from '@components/Forms/CreateDbtProjectForm';
import {
  DEFAULT_URL,
  getCredentialsByType,
  getDatasourceOptions,
  REQUIRED_FIELDS,
} from '@components/Forms/CreateDbtProjectForm/utils';
import Link from '@components/Link';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';
import stripSpaces from '@utils/stripSpaces';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const EDIT_CUSTOM_FIELD_PROPS = {
  [FieldKey.dialect]: { disabled: true },
  [FieldKey.targetDataSource]: { disabled: true },
};

const MULTIPLE_CUSTOM_FIELD_PROPS = {
  [FieldKey.cloudBaseUrl]: { disabled: true },
  [FieldKey.cloudJobId]: { disabled: true },
  [FieldKey.cloudToken]: { disabled: true },
  [FieldKey.coreCatalogJson]: { disabled: true },
  [FieldKey.coreManifestJson]: { disabled: true },
  [FieldKey.coreRunResultsJson]: { disabled: true },
  [FieldKey.dbtType]: { disabled: true },
};

const DbtForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name,
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { dataSources } = useUserContext();
  const isPatch = Boolean(dataSource?.guid);

  const {
    error,
    isLoading: isSavingDataSource,
    mutate,
  } = useDataSourceMutation({ dataSource, onSuccess });

  const { data, isLoading } = useFetchDbtProjects({
    enabled: Boolean(dataSource?.guid),
    params: {
      data_source: dataSource?.guid,
      page_size: 1,
      query: stripSpaces(`{
        dbt_type,
        guid,
        target_data_source{
          guid,
          type
        }
      }`),
    },
    refetchOnMount: true,
    staleTime: 0,
  });

  const hasMultipleProjects = Boolean((data?.count ?? 0) > 1);
  const dbtProject = data?.results?.[0];
  const userDWHOptions = getDatasourceOptions(dataSources);

  const { handleChange, handleSubmit, setValues, values } = useForm<FormValues>({
    initialValues: {
      cloudBaseUrl: isPatch ? undefined : DEFAULT_URL,
      cloudJobId: undefined,
      cloudToken: undefined,
      coreCatalogJson: undefined,
      coreManifestJson: undefined,
      coreRunResultsJson: undefined,
      dbtType: isPatch ? dbtProject?.dbtType! : DbtTypes.cloud,
      dialect: isPatch ? dbtProject?.dialect : userDWHOptions?.[0]?.value.type ?? 'snowflake',
      name: dataSource?.name ?? name,
      targetDataSource: isPatch ? dbtProject?.targetDataSource : userDWHOptions?.[0]?.value,
    },
    onSubmit: (val) => {
      mutate({
        credentials: {
          dbt_type: val.dbtType,
          dialect: val.dialect ?? val.targetDataSource?.type,
          name: val.name,
          target_data_source: val.targetDataSource,
          ...getCredentialsByType(val),
        },
        name: val.name,
        type: dataType,
      });
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
    syncInitialValues: true,
  });

  const customFieldProps = {
    ...(isPatch && EDIT_CUSTOM_FIELD_PROPS),
    ...(hasMultipleProjects && MULTIPLE_CUSTOM_FIELD_PROPS),
  };

  const isInvalid = isPatch
    ? false
    : REQUIRED_FIELDS[values.dbtType].some((key) => Boolean(values[key]) === false);

  return (
    <CreateDbtProjectForm
      customFieldProps={customFieldProps}
      error={error}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isLoading={isLoading || isSavingDataSource}
      renderBefore={renderBefore}
      setValues={setValues}
      showEditMsg={isPatch}
      userDWHOptions={userDWHOptions}
      values={values}
    >
      {hasMultipleProjects && (
        <Box pb={3} px={3}>
          <Alert type="info">
            Please go to the datasource{' '}
            <Link fontSize="inherit" href={`/admin/dbt/${dataSource?.guid}/projects`} underline>
              projects
            </Link>{' '}
            to manage all your linked dbt projects.
          </Alert>
        </Box>
      )}
      {children?.({ error, loading: isLoading || isInvalid })}
    </CreateDbtProjectForm>
  );
};

export default DbtForm;
