import React, { useCallback, useEffect } from 'react';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import IconButton from '@components/IconButton';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { useModal } from '@context/Modal';
import MetadataDecorator from '@utils/MetadataDecorator';
import useLocalStorage from '@utils/useLocalStorage';

import {
  StyledPageColumnTemplate,
  StyledPageColumnTemplateResize,
} from './PageColumnTemplate.styles';

export interface PageColumnTemplateProps {
  header: React.ReactNode;
  isAuthorized: boolean;
  isError: boolean;
  isLoading: boolean;
  onClose: () => void;
  tabsRouterConfig: TabRoutesConfig[];
  title?: string;
}

const PageColumnTemplate: React.FC<PageColumnTemplateProps> = ({
  header,
  isAuthorized,
  isError,
  isLoading,
  onClose,
  tabsRouterConfig,
  title,
}) => {
  const { MODAL_IDS, closeModal, openModal } = useModal();
  const [width, setWidth] = useLocalStorage<string | number>('columnPageWidth', '40vw');

  const handleClose = useCallback(() => {
    closeModal(MODAL_IDS.columnPage);
    onClose?.();
  }, [MODAL_IDS.columnPage, closeModal, onClose]);

  useEffect(() => {
    openModal(MODAL_IDS.columnPage);
  }, [MODAL_IDS.columnPage, closeModal, openModal]);

  if (!isAuthorized || isError) {
    return (
      <StyledPageColumnTemplate onClose={handleClose}>
        <NotFoundError />
      </StyledPageColumnTemplate>
    );
  }

  return (
    <>
      <MetadataDecorator title={title} />
      <StyledPageColumnTemplate
        onClose={handleClose}
        renderContent={({ modalHandleClose }) => (
          <StyledPageColumnTemplateResize
            defaultSize={{
              height: '100%',
              width,
            }}
            enable={{
              left: true,
            }}
            maxHeight="100%"
            maxWidth="99vw"
            minWidth={310}
            onResizeStop={(_e, _direction, ref) => {
              setWidth(ref.offsetWidth);
            }}
          >
            {isLoading ? (
              <CircularLoader compDisplay="block" compSize={5} cover />
            ) : (
              <>
                <Box compDisplay="flex" justifyContent="space-between" mb={1}>
                  <Box compDisplay="flex" compWidth="100%" flexDirection="column">
                    {header}
                  </Box>
                  <IconButton
                    iconName="close"
                    iconSize="16px"
                    onClick={modalHandleClose}
                    variant="clear"
                  />
                </Box>
                <TabsRouter config={tabsRouterConfig} />
              </>
            )}
          </StyledPageColumnTemplateResize>
        )}
      />
    </>
  );
};

export default PageColumnTemplate;
