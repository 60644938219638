import React, { useCallback, useEffect, useRef } from 'react';
import uniqBy from 'lodash/uniqBy';

import { useFetchColumns } from '@api/columns';
import { ColumnModel } from '@api/columns/ColumnModel';
import { DatabaseModel } from '@api/databases/DatabaseModel';
import { usePatchMetadataById } from '@api/metadata';
import { SchemaModel } from '@api/schema/SchemaModel';
import Box from '@components/Box';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import CircularLoader from '@components/CircularLoader';
import FavoriteButton from '@components/FavoriteButton';
import { GridContainer } from '@components/Grid';
import OwnerSelect from '@components/OwnerSelect';
import type { OwnerSelectItem } from '@components/OwnerSelect/types';
import Popularity from '@components/Popularity';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import ColumnsTable, {
  COLUMN_TABLE_SEARCH_CONFIG,
  COLUMN_TABLE_SORT_CONFIG,
  ColumnKey,
} from '@components/TabContent/ColumnsTab/ColumnTable';
import TaggedItems from '@components/TaggedItems';
import Text from '@components/Text';
import { renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import HR from '@components/UI/HR';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import theme from '@styles/theme';
import { Filter } from '@utils';
import MetadataDecorator from '@utils/MetadataDecorator';

import TableFilters from './Filters';
import ItemsCount from './ItemsCount';
import { StyledPageHeader, StyledTablesPage } from './TablesPage.styles';
import type { TablePageProps } from './types';

const VISIBLE_COLUMNS = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.description,
  ColumnKey.tags,
  ColumnKey.popularity,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.downstreamChartsCounts,
  ColumnKey.upstreamObjectsCounts,
];

export const COLUMNS_PAGE_DEFAULT_HIDDEN_COLUMNS = [
  ColumnKey.tags,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.downstreamChartsCounts,
  ColumnKey.upstreamObjectsCounts,
];

const ColumnsPage: React.FC<TablePageProps> = ({
  businessOwner,
  customColumnProps,
  data,
  dataSourceData,
  dataSourceType,
  defaultConfig,
  description,
  guid,
  hiddenColumns = COLUMNS_PAGE_DEFAULT_HIDDEN_COLUMNS,
  icon,
  objectType,
  popularity,
  reloadData,
  showFilters,
  showObjectBreadcrumbs,
  supIcon,
  supTitle,
  taggedItems,
  technicalOwner,
  title,
  tooltipText,
}) => {
  const allFetchedFields = useRef<ColumnModel[]>([]);
  const { dataSources, organization } = useUserContext();
  const {
    isEditable: isObjectEditable,
    isPbac,
    permissions,
  } = useObjectPermissionsContext({ id: guid });
  const { selected, setSelected, tagsCounts } = useBulkEditSelected<ColumnModel>();
  const { selected: currentObjectSelected, tagsCounts: currentObjectTagsCount } =
    useBulkEditSelected<SchemaModel | DatabaseModel>({
      defaultSelected: data ? [data] : undefined,
    });
  const FilterService = Filter.useUpdateFilters(
    defaultConfig,
    COLUMN_TABLE_SEARCH_CONFIG,
    COLUMN_TABLE_SORT_CONFIG,
  );
  const { filter } = FilterService;
  const showColumnCategoryTags = organization?.settings?.showColumnCategoryTags || false;
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );

  const {
    data: columnsResponse,
    isLoading: isColumnsLoading,
    refetch: refetchColumns,
  } = useFetchColumns({
    params: {
      ...Filter.setParams(filter),
      force_showing_suggested_description: true,
    },
  });

  const columns = columnsResponse ? columnsResponse?.results : undefined;
  const tablesCount = columnsResponse?.table_count;
  const schemasCount = columnsResponse?.schema_count;
  const columnsCount = columnsResponse?.count;
  const modelCount = columnsResponse?.model_count;
  const dsGuid = columns?.[0]?.table?.database?.dataSource?.guid ?? '';
  const showModelsCount = dataSources && dataSources[dsGuid]?.type === 'dbt';
  const isDataSourceEditable = Boolean(dataSources?.[dsGuid]?.settings?.isEditable);
  const isEditable = isDataSourceEditable && isObjectEditable;
  const showOwners = dataSourceType !== 'dbt';

  const { isSuccess: isMetadataPatchSuccess, mutate: patchMetadata } = usePatchMetadataById(guid, {
    onSuccess: () => {
      refetchColumns();
      renderInfoToast(`${title} description has been updated.`);
    },
  });

  const totalPages =
    columnsResponse && filter.page_size ? Math.ceil(columnsResponse.count / filter.page_size) : 1;

  useEffect(() => {
    allFetchedFields.current = uniqBy(
      [...(columnsResponse?.results ?? []), ...allFetchedFields.current],
      'guid',
    );
  }, [columnsResponse]);

  const handleSetSelectRowIds = useCallback(
    (selectedRowIds) => {
      setSelected(allFetchedFields.current?.filter((i) => selectedRowIds?.[i.guid]));
    },
    [setSelected],
  );

  return (
    <Box compDisplay="flex">
      <MetadataDecorator title={title} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <StyledTablesPage>
          <StyledPageHeader
            icon={
              icon && (
                <Tooltip content={tooltipText}>
                  <Icon alignSelf="center" color={theme.colors.gray[600]} name={icon} size="30px" />
                </Tooltip>
              )
            }
            supIcon={supIcon && <Icon ml={0.5} name={supIcon} size="16px" />}
            supTitle={supTitle}
            title={title}
            titleSuffix={
              <ItemsCount
                columnsCount={columnsCount}
                modelCount={showModelsCount ? modelCount : undefined}
                schemasCount={schemasCount}
                tablesCount={tablesCount}
              />
            }
            titleToolBox={
              <>
                {popularity && (
                  <Box compDisplay="flex">
                    <Popularity
                      priority={popularity?.formattedPopularity}
                      text={`Popularity score: ${popularity?.popularity}`}
                    />
                  </Box>
                )}
                {guid && title && <FavoriteButton objGuid={guid} objName={title} />}
                {taggedItems && (
                  <TaggedItems
                    isEditable={isEditable}
                    objects={currentObjectSelected.items}
                    taggedItemsCounts={currentObjectTagsCount}
                  />
                )}
              </>
            }
          />
          {showOwners && (
            <Box
              alignItems="center"
              compDisplay="flex"
              data-testid="owners-container"
              mb={1.25}
              px={1}
            >
              <Box alignItems="center" compDisplay="flex" mr={3}>
                <Text
                  as="span"
                  color="gray.500"
                  fontSize={theme.typography.fontSizes.body2}
                  mr={1}
                  whiteSpace="nowrap"
                >
                  Business Owner
                </Text>
                <OwnerSelect
                  hasEditPermissions={Boolean(isEditable)}
                  items={[dataSourceData as OwnerSelectItem]}
                  itemsType={dataSourceData?.dataTypes?.dataType}
                  owner={businessOwner?.obj}
                  ownerUpdateType="business"
                  reloadData={reloadData}
                />
              </Box>
              <Box alignItems="center" compDisplay="flex">
                <Text
                  as="span"
                  color="gray.500"
                  fontSize={theme.typography.fontSizes.body2}
                  mr={1}
                  whiteSpace="nowrap"
                >
                  Technical Owner
                </Text>
                <OwnerSelect
                  hasEditPermissions={Boolean(isEditable)}
                  items={[dataSourceData as OwnerSelectItem]}
                  itemsType={dataSourceData?.dataTypes?.dataType}
                  owner={technicalOwner?.obj}
                  ownerUpdateType="technical"
                  reloadData={reloadData}
                />
              </Box>
            </Box>
          )}
          <Box compDisplay="flex" mb={2} px={1}>
            <RichTextDescriptionEditor
              descriptions={{ description }}
              editIconVariant="always"
              fontSize={theme.typography.fontSizes.body2}
              guid={guid}
              isEditable={isEditable}
              isSuccess={isMetadataPatchSuccess}
              maxLength={250}
              onDescriptionSave={(richtextDesc, plainTextDesc, descSource) => {
                patchMetadata({
                  description: plainTextDesc,
                  description_source: descSource,
                  richtext_description: richtextDesc,
                });
              }}
              placeholder="Description"
            />
          </Box>
          <HR />
          <BulkButtons
            canEditTags
            hideCategoryTags={!showColumnCategoryTags}
            isDataSourceEditable={isDataSourceEditable}
            selectedEditableItems={selectedEditableItems}
            selectedItems={selected.items}
            showText
            tagsCounts={tagsCounts}
          />
          <Box mb={0.5}>
            <ColumnsTable
              customColumnProps={customColumnProps as any}
              data={columns}
              disableRowSelect={false}
              filterService={FilterService}
              hiddenColumns={hiddenColumns as any}
              isDataSourceEditable={isDataSourceEditable}
              isLoading={isColumnsLoading}
              itemCount={columnsResponse?.count}
              pageObjectType={objectType}
              selectedRowIds={selected.ids}
              setSelectedRowIds={handleSetSelectRowIds}
              showBreadcrumbs={showObjectBreadcrumbs}
              totalPages={totalPages}
              visibleColumns={VISIBLE_COLUMNS}
            />
          </Box>
        </StyledTablesPage>
      </GridContainer>
      {showFilters && (
        <React.Suspense fallback={<CircularLoader borderWidth={1} centered compSize={2} />}>
          <TableFilters dataSourceType={dataSourceType} filterService={FilterService} />
        </React.Suspense>
      )}
    </Box>
  );
};

export default React.memo(ColumnsPage);
