import styled from '@emotion/styled';

export const StyledQueryModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin: -5px 0px;
`;

export const StyledQueryModalFooterSeparator = styled.div`
  flex-grow: 1;
`;

export const StyledQueryModalFooterPanel = styled.div`
  text-align: right;
`;

export const StyledQueryModalCodeEditorWrapper = styled.div`
  position: relative;
`;
