import styled from '@emotion/styled';

import Box from '@components/Box';
import { sizeVariants } from '@components/Input/Input.v1.styles.variants';
import InputLabel from '@components/UI/Form/InputLabel';

export const StyleCreateTagContentTagDropdown = styled.div`
  .ui.dropdown {
    position: relative;
    margin: auto;
    border: solid 1px #e2e9f0;
    justify-content: center;
    border-radius: ${({ theme }) => theme.radius.default};
    width: 36px;
    ${sizeVariants.default};
  }

  .ui.dropdown > .dropdown.icon {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .ui.dropdown .menu {
    display: flex !important;
    flex-direction: row;
    flex-flow: wrap;
  }
  .ui.dropdown .menu > .item {
    min-width: 0 !important;
    margin: 0;
    display: inline-flex;
    flex: 1 0 14%;
    width: 20px;
    margin: 3px;
    padding: 5px !important;
    svg {
      margin: 1px;
      width: 25px;
      height: 25px;
      border: solid 2px #ffffff;
    }
  }
  .ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    background: transparent;
    svg {
      border: solid 2px #cacfd6;
      border-radius: 50%;
    }
  }

  .ui.dropdown .menu > .item:hover {
    background: transparent;
    svg {
      border: solid 2px #cacfd6;
      border-radius: 50%;
    }
  }

  .ui.dropdown > .text {
    margin: auto;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .ui.dropdown > .text > svg {
    margin: auto;
    position: relative;
    width: 20px;
    height: 20px;
  }
`;

export const StyledCreateTagContentFieldLabel = styled(InputLabel)``;

StyledCreateTagContentFieldLabel.defaultProps = {
  alignItems: 'flex-start',
  as: 'div',
  color: 'gray.700',
  compDisplay: 'grid',
  cursor: 'default',
  fontWeight: 'medium',
  gap: 2,
  gridTemplateColumns: '80px 1fr',
};

export const StyledCreateTagContentNameColorFieldLabel = styled(StyledCreateTagContentFieldLabel)`
  grid-template-columns: 80px 38px 2fr;
  row-gap: 0;
`;

export const StyledCreateTagContentNameColorFieldLabelText = styled(Box)`
  ${sizeVariants.default};
`;

StyledCreateTagContentNameColorFieldLabelText.defaultProps = {
  alignItems: 'center',
  as: 'span',
  compDisplay: 'flex',
};
