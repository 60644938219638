import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Cell } from 'react-table';

import { ColumnModel } from '@api/columns/ColumnModel';
import { ObjectType } from '@api/types';
import EditPkFkModal from '@components/Modal/EditPkFkModal';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import FormattedNumberCell from '@components/Table/Cells/FormattedNumberCell';
import ImageCell from '@components/Table/Cells/ImageCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import RelatedObjectsCountCell from '@components/Table/Cells/RelatedObjectsCountsCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import TextCell from '@components/Table/Cells/TextCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Tooltip from '@components/Tooltip';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import { ValidDSType } from '@models/DataSourceCredentials';
import ColumnPage from '@pages/ColumnPage';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';

import ColumnConstraint from './ColumnConstraint';
import ColumnNameTableCell from './ColumnNameTableCell';
import ColumnTableIndexCell from './ColumnTableIndexCell';

export enum ColumnKey {
  constraints = 'constraints',
  customAttribute = 'customAttribute',
  dataType = 'dataType',
  description = 'description',
  downstreamChartsCounts = 'downstreamChartsCounts',
  downstreamObjectsCounts = 'downstreamObjectsCounts',
  indexes = 'indexes',
  name = 'name',
  popularity = 'popularity',
  search = 'search',
  tags = 'tags',
  upstreamObjectsCounts = 'upstreamObjectsCounts',
}

const DEFAULT_COLUMNS = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.dataType,
  ColumnKey.description,
  ColumnKey.customAttribute,
  ColumnKey.tags,
  ColumnKey.downstreamChartsCounts,
  ColumnKey.popularity,
];

const DEFAULT_HIDDEN_COLUMNS = [
  ColumnKey.dataType,
  ColumnKey.constraints,
  ColumnKey.indexes,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.downstreamChartsCounts,
  ColumnKey.upstreamObjectsCounts,
];

export const COLUMN_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof Filter.FilterOptions> = {
  description: 'search_description',
  name: 'search_name',
  tags: 'search_tags',
};

export const COLUMN_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  dataType: 'external_type',
  description: 'description',
  downstreamChartsCounts: 'downstream_charts',
  downstreamObjectsCounts: 'downstream_objects_total',
  name: 'name',
  popularity: 'popularity,name',
  upstreamObjectsCounts: 'upstream_objects_total',
};

export interface ColumnTableProps {
  customColumnProps?: PartialRecord<
    keyof typeof ColumnKey,
    Partial<Pick<ColumnConfig<ColumnModel>, 'dropdownCheckboxLabel'>>
  >;
  data?: ColumnModel[];
  dataSourceType?: ValidDSType;
  disableRowSelect?: boolean;
  filterService: Filter.FilterServiceInterface;
  hiddenColumns?: Array<keyof typeof ColumnKey>;
  isDataSourceEditable: boolean;
  isLoading: boolean;
  itemCount?: number;
  pageObjectType?: ObjectType;
  selectedRowIds?: { [guid: string]: boolean };
  setSelectedRowIds?: React.Dispatch<React.SetStateAction<{}>>;
  showBreadcrumbs?: boolean;
  totalPages?: number;
  visibleColumns?: Array<keyof typeof ColumnKey>;
}

const ColumnsTable: React.FC<ColumnTableProps> = ({
  customColumnProps,
  data,
  dataSourceType,
  disableRowSelect = true,
  filterService,
  hiddenColumns = DEFAULT_HIDDEN_COLUMNS,
  isDataSourceEditable,
  isLoading,
  itemCount = 0,
  pageObjectType,
  selectedRowIds,
  setSelectedRowIds,
  showBreadcrumbs,
  totalPages,
  visibleColumns = DEFAULT_COLUMNS,
}) => {
  const history = useHistory();
  const { organization } = useUserContext();
  const showColumnCategoryTags = organization?.settings?.showColumnCategoryTags || false;
  const { guid, itemId } = useParams<{ guid: string; itemId: string }>();
  const [isShowFilter, setShowFilter] = useState(false);
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();
  const [currColumn, setCurrColumn] = useState<string | undefined>(itemId);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;
  const { dataSourceGuid } = useMetadataObjectContext();
  const customAttributesAssetType = `${dataSourceType},column,`;

  const { customAttributesColumns } = useCustomAttributesColumns<ColumnModel>({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const columns: ColumnConfig<ColumnModel>[] = useMemo(() => {
    const all: Record<Exclude<ColumnKey, 'customAttribute'>, ColumnConfig<ColumnModel>> = {
      constraints: {
        Cell: ({ row: { original } }: Cell<ColumnModel>) => (
          <ColumnConstraint
            columnItem={original}
            compHeight="100%"
            compWidth="100%"
            editPk={() => openModal(MODAL_IDS.editPkFk, { column: original })}
            isEditable={isDataSourceEditable}
          />
        ),
        Header: 'Constraints',
        accessor: (d) => d.name,
        disableFilters: true,
        disableSortBy: true,
        id: 'constraints',
        width: '110%',
      },
      dataType: {
        Cell: (props: Cell<ColumnModel>) => {
          const { row } = props;
          const column = row.original;
          return <TextCell {...props} text={column.externalType} />;
        },
        Header: 'Data Type',
        accessor: (d) => d.externalType,
        disableFilters: true,
        id: 'dataType',
        width: '110%',
      },
      description: {
        Cell: (props: Cell<ColumnModel>) => {
          const { row } = props;
          const column = row.original;

          return (
            <DescriptionCell
              {...props}
              aiDescription={column.aiDescription}
              dataSourceType={column.dataTypes?.dataSourceType}
              description={column.description}
              descriptionSource={column.descriptionSource}
              guid={column.guid}
              ingestedDescription={column.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={column.name}
              parentGuid={column.parentGuid}
              richtextDescription={column.richtextDescription}
              suggestedDescription={column.suggestedDescription}
              suggestedDescriptionSource={column.suggestedDescriptionSource}
              suggestedDescriptionSourceObj={column.suggestedDescriptionSourceObject?.obj}
              userDescription={column.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: (d) => d.description,
        id: 'description',
        width: '150%',
      },
      downstreamChartsCounts: {
        Cell: ({ row: { original } }: Cell<ColumnModel>) => (
          <FormattedNumberCell number={original.downstreamObjectsCounts?.chart ?? 0} />
        ),
        Header: (
          <Tooltip content="Count of downstream charts from data lineage">
            <span>Downstream Charts</span>
          </Tooltip>
        ),
        accessor: (d) => d.downstreamObjectsCounts?.chart,
        disableFilters: true,
        dropdownCheckboxLabel: 'Downstream Charts',
        id: 'downstreamChartsCounts',
        sortDescFirst: true,
        width: 170,
      },
      downstreamObjectsCounts: {
        Cell: (props: Cell<ColumnModel>) => {
          const table = props.row.original;
          return <RelatedObjectsCountCell {...props} counts={table.downstreamObjectsCounts} />;
        },
        Header: (
          <Tooltip content="Count of downstream tables and dashboards from data lineage">
            <span>Downstream</span>
          </Tooltip>
        ),
        accessor: (d) => d.downstreamObjectsCounts?.total,
        disableFilters: true,
        dropdownCheckboxLabel: 'Downstream',
        id: 'downstreamObjectsCounts',
        sortDescFirst: true,
        width: 110,
      },
      indexes: {
        Cell: ({ row: { original: column } }: Cell<ColumnModel>) => (
          <ColumnTableIndexCell columnItem={column} />
        ),
        Header: 'Indexes',
        disableFilters: true,
        disableSortBy: true,
        id: 'indexes',
        width: '110%',
      },
      name: {
        Cell: ({ column, row: { original } }: Cell<ColumnModel>) => {
          return (
            <ColumnNameTableCell
              breadcrumbFixedUrl={original.routePath}
              column={column}
              columnItem={original}
              customUrl={original.routePath}
              editPk={() => openModal(MODAL_IDS.editPkFk, { column: original })}
              hideConstraints={visibleColumns.includes('constraints')}
              isDataSourceEditable={isDataSourceEditable}
              openColumnsPage={() => {
                setCurrColumn(original.guid);
              }}
              pageObjectType={pageObjectType}
              showBreadcrumbs={showBreadcrumbs}
            />
          );
        },
        Header: `Column (${itemCount})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      popularity: {
        Cell: (props: Cell<ColumnModel>) => {
          const { row } = props;
          const column = row.original;
          return <PopularityCell {...props} popularity={column.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        dropdownCheckboxLabel: customColumnProps?.popularity?.dropdownCheckboxLabel,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
      search: {
        Cell: (props: Cell<ColumnModel>) => {
          const column = props.row.original;
          return column.isJoinKey ? (
            <ImageCell
              {...props}
              imageName="join-link"
              tooltipText="This field is used to join other datasets"
            />
          ) : null;
        },
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      tags: {
        Cell: (props: Cell<ColumnModel>) => {
          const { row } = props;
          const column = row.original;

          return (
            <EditableTaggedItemCell
              {...props}
              hideCategoryTags={!showColumnCategoryTags}
              isDataSourceEditable={isDataSourceEditable}
              obj={column}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => {
          return d.taggedItems ? `${d.taggedItems.map((item) => item.tag.name).join(',')}` : null;
        },
        disableSortBy: true,
        id: 'tags',
        width: '110%',
      },
      upstreamObjectsCounts: {
        Cell: (props: Cell<ColumnModel>) => {
          const table = props.row.original;
          return <RelatedObjectsCountCell {...props} counts={table.upstreamObjectsCounts} />;
        },

        Header: (
          <Tooltip content="Count of upstream objects from data lineage">
            <span>Upstream</span>
          </Tooltip>
        ),
        accessor: (d) => d.upstreamObjectsCounts?.total,
        disableFilters: true,
        dropdownCheckboxLabel: 'Upstream',
        id: 'upstreamObjectsCounts',
        sortDescFirst: true,
        width: 100,
      },
    };

    let columnArray: ColumnConfig<ColumnModel>[] = [];

    visibleColumns.forEach((col) => {
      if (col === 'customAttribute') {
        columnArray = columnArray.concat(customAttributesColumns);
      } else {
        columnArray.push(all[col]);
      }
    });

    return columnArray;
  }, [
    itemCount,
    customColumnProps,
    pageObjectType,
    visibleColumns,
    isDataSourceEditable,
    showBreadcrumbs,
    MODAL_IDS,
    openModal,
    showColumnCategoryTags,
    customAttributesColumns,
  ]);

  const getRowId = useCallback((row: Partial<ColumnModel>) => row.guid!, []);
  const EditPkFkModalProps = getModalContent(MODAL_IDS.editPkFk);

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disableRowSelect={disableRowSelect}
          getRowId={getRowId}
          initialState={{
            hiddenColumns,
            pageIndex: filter.page ? filter.page - 1 : 0,
            ...(selectedRowIds && { selectedRowIds }),
            sortBy: initialTableSortState,
          }}
          loading={isLoading}
          manualFilters
          manualPagination
          manualSortBy
          selectable
          setFilters={search}
          setSelectedRowIds={setSelectedRowIds}
          setSortBy={sort}
          showFilter={isShowFilter}
          singleLine
          sortable
          stickyHeader
          toggleFilter={() => setShowFilter((prev) => !prev)}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
      {checkModalOpened(MODAL_IDS.editPkFk) && EditPkFkModalProps && (
        <EditPkFkModal
          column={EditPkFkModalProps.column}
          dsGuid={dataSourceGuid}
          onClose={() => closeModal(MODAL_IDS.editPkFk)}
        />
      )}
      {currColumn && (
        <ColumnPage
          dsGuid={dataSourceGuid}
          guid={currColumn}
          hideCategoryTags={!showColumnCategoryTags}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            history.push(`/tables/${guid}/columns`);
            setCurrColumn(undefined);
          }}
        />
      )}
    </>
  );
};

export default React.memo(ColumnsTable);
