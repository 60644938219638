import React from 'react';

import {
  useFetchMetadataOwnedObjectsByOwnerId,
  usePatchMetadataOwnedObjectsByOwnerId,
} from '@api/metadata';
import { useDeleteOrganizationUser } from '@api/organizationUser';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import BaseOwnerSelect from '@components/OwnerSelect/BaseOwnerSelect';
import { invalidateAppOwnersCache } from '@components/OwnerSelect/utils';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import { Option, SelectValue } from '@components/UI/Select.v1/types';

import useDefaultOptions from './useDefaultOptions';
import {
  formatUserNameAndTeam,
  getTextBasedOnObjectCount,
} from './UserOwnershipMigrationModal.utils';

interface UserOwnershipMigrationModalForm {
  newOwner?: SelectValue;
}

export interface UserOwnershipMigrationModalProps {
  onClose?: () => void;
  user: UserModel;
}

const UserOwnershipMigrationModal = ({ onClose, user }: UserOwnershipMigrationModalProps) => {
  const { data: ownedObjects, isLoading: isLoadingOwnedItemsCounts } =
    useFetchMetadataOwnedObjectsByOwnerId(user.guid);

  const { isLoading: isDeletingUser, mutate: deleteUser } = useDeleteOrganizationUser(user.guid, {
    onError: () => {
      renderErrorToast(`Couldn't remove ${user.fullName} from Select Star`);
    },
    onSuccess: () => {
      renderInfoToast(`${user.fullName} has been removed from Select Star`);
      invalidateAppOwnersCache();
      onClose?.();
    },
  });

  const { isLoading: isUpdatingOwner, mutate: updateObjectsOwner } =
    usePatchMetadataOwnedObjectsByOwnerId(user.guid, {
      onError: () => {
        renderErrorToast("Couldn't transfer ownership of user's items");
      },
      onSuccess: () => {
        renderInfoToast('Ownership has been updated');
        deleteUser(null);
      },
    });

  const { isLoading: isLoadingDefaultUsers, options: ownerDefaultOptions } =
    useDefaultOptions(user);

  const { handleSubmit, setValues, values } = useForm<UserOwnershipMigrationModalForm>({
    initialValues: {
      newOwner: undefined,
    },
    onSubmit: (data) => {
      const [newOwnerOption] = data.newOwner ?? [];

      if (newOwnerOption) {
        updateObjectsOwner({ new_owner: newOwnerOption.value });
      } else {
        deleteUser(null);
      }
    },
  });

  const handleOwnerChange = (newValue: SelectValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      newOwner: newValue as Option[],
    }));
  };

  const userName = formatUserNameAndTeam(user);
  const userHasOwnedItems = Boolean(ownedObjects?.counts?.total && ownedObjects.counts.total > 0);

  const isLoadingInitialData = isLoadingOwnedItemsCounts || isLoadingDefaultUsers;

  const isSaving = isUpdatingOwner || isDeletingUser;

  return (
    <Modal aria-labelledby="dialog-header" closeOnDimmerClick={false} m="auto" onClose={onClose}>
      <ModalHeader onClose={onClose} title="Delete User" />
      <Form isLoading={isSaving} onSubmit={handleSubmit}>
        <ModalContent
          compDisplay="flex"
          compWidth="100%"
          flexDirection="column"
          gap={2.25}
          minHeight="auto"
          py={2}
        >
          {isLoadingInitialData ? (
            <Box alignItems="center" compDisplay="flex" compHeight="200px" justifyContent="center">
              <CircularLoader centered />
            </Box>
          ) : (
            <>
              <Box color="gray.700" fontSize="body2">
                Are you sure you want to remove{' '}
                <Box as="span" fontWeight="bold">
                  {userName}
                </Box>{' '}
                from your organization?{' '}
                {userHasOwnedItems && (
                  <>
                    <Box as="span" fontWeight="bold">
                      {userName}
                    </Box>{' '}
                    owns {ownedObjects?.counts.total}{' '}
                    {getTextBasedOnObjectCount({
                      count: ownedObjects?.counts.total as number,
                      terms: { plural: 'objects', singular: 'object' },
                    })}
                    .
                  </>
                )}
              </Box>
              {userHasOwnedItems && (
                <>
                  <Box color="gray.700" fontSize="body2">
                    Optionally select a user or team to migrate ownership to:
                  </Box>
                  <BaseOwnerSelect
                    defaultOptions={ownerDefaultOptions}
                    fitAnchorWidth
                    hideCaret
                    onChange={handleOwnerChange}
                    placeholder="Search for users and teams"
                    showClearSelection
                    showNoOwnerOption
                    value={values.newOwner}
                  />
                </>
              )}
            </>
          )}
        </ModalContent>
        {!isLoadingInitialData && (
          <ModalFooter>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit">Delete User</Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

export default UserOwnershipMigrationModal;
