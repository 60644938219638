import React, { useRef } from 'react';

import invalidateCache from '@api/invalidateCache';
import {
  useFetchMetadataOwnedObjectsByOwnerId,
  usePatchMetadataOwnedObjectsByOwnerId,
} from '@api/metadata';
import { useDeleteTeam, usePatchTransferTeamUsers } from '@api/teams';
import { TeamModel } from '@api/teams/TeamModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import BaseOwnerSelect from '@components/OwnerSelect/BaseOwnerSelect';
import { invalidateAppOwnersCache } from '@components/OwnerSelect/utils';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import Select from '@components/UI/Select.v1/Select';
import { Option, SelectValue } from '@components/UI/Select.v1/types';

import { getTextBasedOnObjectCount } from '../UserOwnershipMigrationModal/UserOwnershipMigrationModal.utils';

import useDefaultOptions from './useDefaultOptions';
import useNewTeamsOptions from './useNewTeamsOptions';

interface TeamOwnershipMigrationModalForm {
  newOwner?: SelectValue;
  newTeam?: SelectValue;
}

export interface TeamOwnershipMigrationModalProps {
  onClose?: () => void;
  team: TeamModel;
}

const TeamOwnershipMigrationModal = ({ onClose, team }: TeamOwnershipMigrationModalProps) => {
  const selectedNewOwner = useRef<Option['value'] | undefined>(undefined);
  const { data: ownedObjects, isLoading: isLoadingOwnedItemsCounts } =
    useFetchMetadataOwnedObjectsByOwnerId(team.guid);

  const { isLoading: isLoadingTeams, options: teamsOptions } = useNewTeamsOptions(team);

  const { isLoading: isDeletingTeam, mutate: deleteTeam } = useDeleteTeam(team.guid, {
    onError: () => {
      renderErrorToast(`Couldn't remove ${team.name} from Select Star`);
    },
    onSuccess: () => {
      renderInfoToast(`${team.name} has been removed from Select Star`);
      invalidateAppOwnersCache();
      invalidateCache((keys) => [keys.teams.all, keys.user.me]);
      onClose?.();
    },
  });

  const { isLoading: isUpdatingOwner, mutate: updateObjectsOwner } =
    usePatchMetadataOwnedObjectsByOwnerId(team.guid, {
      onError: () => {
        renderErrorToast("Couldn't transfer ownership of team's items");
      },
      onSuccess: () => {
        renderInfoToast('Ownership has been updated');
        deleteTeam(null);
      },
    });

  const { isLoading: isAssigningNewTeam, mutate: transferTeamUsers } = usePatchTransferTeamUsers(
    team.guid,
    {
      onError: () => {
        renderErrorToast("Couldn't assign users to a new team");
      },
      onSuccess: () => {
        renderInfoToast('Users have been assigned to a new team');
        if (selectedNewOwner.current) {
          updateObjectsOwner({ new_owner: selectedNewOwner.current });
        } else {
          deleteTeam(null);
        }
      },
    },
  );

  const { isLoading: isLoadingDefaultOptions, options: ownerDefaultOptions } =
    useDefaultOptions(team);

  const { handleSubmit, setValues, values } = useForm<TeamOwnershipMigrationModalForm>({
    initialValues: {
      newOwner: undefined,
      newTeam: undefined,
    },
    onSubmit: (data) => {
      const [newOwnerOption] = data.newOwner ?? [];
      const [newTeamOption] = data.newTeam ?? [];

      selectedNewOwner.current = newOwnerOption?.value;

      if (newTeamOption) {
        transferTeamUsers({
          new_team: newTeamOption.value,
        });
      } else if (newOwnerOption) {
        updateObjectsOwner({ new_owner: newOwnerOption.value });
      } else {
        deleteTeam(null);
      }
    },
  });

  const handleFieldChange = (
    field: keyof TeamOwnershipMigrationModalForm,
    newValue: SelectValue,
  ) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: newValue as Option[],
    }));
  };

  const teamHasOwnedObjects = Boolean(ownedObjects?.counts?.total && ownedObjects.counts.total > 0);
  const teamHasMembers = Boolean(team.membersCount && team.membersCount > 0);

  const isLoadingInitialData =
    isLoadingOwnedItemsCounts || isLoadingDefaultOptions || isLoadingTeams;

  const isSaving = isUpdatingOwner || isDeletingTeam || isAssigningNewTeam;

  const teamName = `${team.name} team`;

  return (
    <Modal aria-labelledby="dialog-header" closeOnDimmerClick={false} m="auto" onClose={onClose}>
      <ModalHeader onClose={onClose} title="Delete Team" />
      <Form isLoading={isSaving} onSubmit={handleSubmit}>
        <ModalContent
          compDisplay="flex"
          compWidth="100%"
          flexDirection="column"
          gap={3}
          minHeight="auto"
          py={2}
        >
          {isLoadingInitialData ? (
            <Box alignItems="center" compDisplay="flex" compHeight="200px" justifyContent="center">
              <CircularLoader centered />
            </Box>
          ) : (
            <>
              <Box color="gray.700" fontSize="body2">
                Are you sure you want to remove{' '}
                <Box as="span" fontWeight="bold">
                  {teamName}{' '}
                  {team.membersCount
                    ? `(${team.membersCount} ${getTextBasedOnObjectCount({
                        count: team.membersCount as number,
                        terms: { plural: 'users', singular: 'user' },
                      })})`
                    : ''}
                </Box>{' '}
                from your organization?{' '}
                {teamHasOwnedObjects && (
                  <>
                    <Box as="span" fontWeight="bold">
                      {teamName}
                    </Box>{' '}
                    owns {ownedObjects?.counts.total}{' '}
                    {getTextBasedOnObjectCount({
                      count: ownedObjects?.counts.total as number,
                      terms: { plural: 'objects', singular: 'object' },
                    })}
                    .
                  </>
                )}
              </Box>
              {teamHasMembers && (
                <Box compDisplay="flex" flexDirection="column" gap={1}>
                  <InputLabel color="gray.700" cursor="default" fontSize="body2" gap={0}>
                    Select a new team for the users to join&nbsp;
                    <Box as="span" color="inherit" fontStyle="italic" fontWeight="normal">
                      (optional)
                    </Box>
                  </InputLabel>
                  <Select
                    hideCaret
                    isLoading={isLoadingTeams}
                    label="newTeamSelect"
                    onChange={(newValue) => handleFieldChange('newTeam', newValue)}
                    options={teamsOptions}
                    placeholder="Search for teams"
                    showClearSelection
                    value={values.newTeam}
                  />
                </Box>
              )}
              {teamHasOwnedObjects && (
                <Box compDisplay="flex" flexDirection="column" gap={1}>
                  <InputLabel color="gray.700" cursor="default" fontSize="body2" gap={0}>
                    Select a user or team to migrate ownership to&nbsp;
                    <Box as="span" color="inherit" fontStyle="italic" fontWeight="normal">
                      (optional)
                    </Box>
                  </InputLabel>
                  <BaseOwnerSelect
                    defaultOptions={ownerDefaultOptions}
                    fitAnchorWidth
                    hideCaret
                    label="newOwnerSelect"
                    onChange={(newValue) => handleFieldChange('newOwner', newValue)}
                    placeholder="Search for teams or users"
                    showClearSelection
                    showNoOwnerOption
                    value={values.newOwner}
                  />
                </Box>
              )}
            </>
          )}
        </ModalContent>
        {!isLoadingInitialData && (
          <ModalFooter>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit">Delete Team</Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

export default TeamOwnershipMigrationModal;
