import React from 'react';

import Box from '@components/Box';
import Input from '@components/Input/Input.v1';
import Icon from '@components/UI/Icon';

interface TableTopBarProps {
  customContent?: React.ReactNode;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
}

const TableTopBar: React.FC<TableTopBarProps> = ({
  customContent,
  onSearchValueChange,
  searchValue,
}) => {
  return (
    <Box alignItems="center" compDisplay="flex" gap={2} mb={0.9}>
      <Input
        aria-label="search"
        compWidth="396px"
        onChange={onSearchValueChange}
        placeholder="Search"
        startIcon={<Icon color="gray.800" name="search" size="20px" />}
        value={searchValue}
      />
      {customContent}
    </Box>
  );
};

export default TableTopBar;
