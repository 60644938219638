import React, { useEffect, useMemo, useRef, useState } from 'react';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import { RecoilState, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Dropdown } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';

import Box from '@components/Box';
import getTreeColumnsConfig from '@components/ExploreSidebar/getTreeColumnsConfig';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import { desiredZoomTable, exploreOptions, searchOptions } from '@components/ExploreTree/atoms';
import IconButton from '@components/IconButton';
import Input from '@components/Input/Input.v1';
import OrderByButton from '@components/OrderByButton';
import { OrderBy } from '@components/OrderByButton/OrderByButton.styles';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import flags from '@features';
import useOverrideCmdCtrlKey from '@hooks/useOverrideCmdCtrlKey';
import ExportToCsvButton from '@pages/DocumentsPage/GlossaryTab/ExportToCsvButton';
import wrapString from '@utils/wrapString';

import getTree from '../getTree';
import sidebarInit from '../sidebarInit';
import SidebarTreeItem from '../SidebarTreeItem';
import type { ClickedGuidAtLevel, SidebarProps } from '../types';

import { SortByPanel, StyledDropdown, TreeContainer } from './SidebarTree.styles';

const DEFAULT_EMPTY_TREE = { key: '0', label: 'No results found.', nodes: [] };
const EXPAND_ALL_COUNT_LIMIT = 700;

const filterByTypeIconsMap = {
  showDashboards: <Icon name="dashboard" size="16px" />,
  showModels: <Icon name="dbt-incremental" size="16px" />,
  showTables: <Icon name="table" size="16px" />,
};

export type SidebarTreeProps = SidebarProps & {
  counts?: {
    downstream: number | undefined;
    upstream: number | undefined;
  };
  customSearchOptions?: RecoilState<SearchOptions>;
  direction: 'right' | 'left';
  filterLevelFrom?: number;
  isExpandingAll?: boolean;
  isLoading?: boolean;
  isLoadingExportCsv?: boolean;
  onExportCsvClick?: () => void;
  showDescriptions?: boolean;
  showExpandAll?: boolean;
  showUsage?: boolean;
};

const SidebarTree: React.FC<SidebarTreeProps> = (props) => {
  const { customSearchOptions } = props;
  const treeSearchOptions = customSearchOptions ?? searchOptions;

  const [search, setSearch] = useRecoilState(treeSearchOptions);
  const resetSearch = useResetRecoilState(treeSearchOptions);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [expandAllState, setExpandAllState] = useState<'collapsed' | 'expanded'>('collapsed');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [options, setOptions] = useRecoilState(exploreOptions);
  const resetExploreOptions = useResetRecoilState(exploreOptions);
  const {
    counts,
    direction,
    filterLevelFrom = 0,
    isExpandingAll,
    isLoading,
    isLoadingExportCsv,
    loadLineage,
    nodeKey: startingKey,
    onExportCsvClick,
    onItemClick,
    showDescriptions,
    showExpandAll = false,
    showUsage,
    tables,
    type,
    zoomOnItemClick = true,
  } = props;
  const [clickedGuidAtLevel, setClickedGuid] = useState<ClickedGuidAtLevel>({ '0': 0 });
  const propIndex = ['left', 'right'].indexOf(direction);
  const [keyboardUser, setKeyboardUser] = useState(false);
  const { allNodes, traversalProps } = sidebarInit(props);
  const setZoomToTableId = useSetRecoilState(desiredZoomTable);
  const { MODAL_IDS, openModal } = useModal();
  const setSearchDebounced = useDebouncedCallback((val: SearchOptions) => {
    setSearch(val);
  }, 500);

  const allNodeIds = useMemo(() => {
    return new Set<string | undefined>([...allNodes.map((t) => t.guid)]);
  }, [allNodes]);

  const startingNode = useMemo(() => {
    if (startingKey?.includes('/co_')) {
      const [, columnGuid] = startingKey.split('/');
      return allNodes.find((node) => node.guid === columnGuid);
    }
    return allNodes.find((node) => node.guid === startingKey);
  }, [startingKey, allNodes]);

  const startingDataSourceType = startingNode?.dataSourceType;

  const handleShowInputToggle = useDebouncedCallback(() => {
    setIsInputVisible(!isInputVisible);

    if (!isInputVisible) {
      searchInputRef?.current?.focus();
    }
  }, 50);

  useOverrideCmdCtrlKey({ onPress: handleShowInputToggle });

  useEffect(() => {
    return () => {
      resetSearch();
      resetExploreOptions();
    };
  }, [resetExploreOptions, resetSearch]);

  const updateSearch = (sortBy: SearchOptions['sortBy'], orderBy: OrderBy) => {
    setSearch((prev) => ({
      ...prev,
      orderBy,
      sortBy,
    }));
  };

  const onSqlButtonClick = (query?: string) => {
    openModal(MODAL_IDS.query, { codeString: query });
  };

  const handleExpandAllClick = () => {
    setExpandAllState((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'));
    loadLineage?.(startingKey ?? '', direction, { maxDepth: 'max' });
  };

  const treeData = useMemo<{
    data: TreeNodeInArray[];
    expandAllKeys: string[] | undefined;
  }>(() => {
    if (!startingKey) {
      return {
        data: [DEFAULT_EMPTY_TREE],
        expandAllKeys: [],
      };
    }

    const expandAllKeys: string[] = [];
    const treeResult = getTree(
      {
        allNodeIds,
        allNodes,
        expandAllKeys,
        maxLevel: isExpandingAll ? undefined : Math.max(...Object.values(clickedGuidAtLevel)) + 1,
        options,
        propIndex,
        search,
        startingDataSourceType,
        startingKey,
        tables,
        traversalProps,
        type,
      },
      startingKey,
    );

    return {
      data: [treeResult ?? DEFAULT_EMPTY_TREE],
      expandAllKeys,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, allNodes, startingKey, propIndex, search]);

  if (!startingKey) {
    return null;
  }

  const treeColumnsConfig = getTreeColumnsConfig({ showDescriptions, showUsage });

  const disableExpandAll = () => {
    if (direction === 'right') {
      return (counts?.downstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
    }
    return (counts?.upstream ?? 0) > EXPAND_ALL_COUNT_LIMIT;
  };

  return (
    <TreeContainer
      className={keyboardUser ? 'keyUser' : ''}
      onClick={() => setKeyboardUser(false)}
      onKeyUp={() => setKeyboardUser(true)}
    >
      <TreeMenu
        key={
          expandAllState === 'expanded' && isExpandingAll
            ? treeData.expandAllKeys?.join()
            : startingKey
        }
        data={treeData.data}
        hasSearch={false}
        initialOpenNodes={
          expandAllState === 'expanded' && isExpandingAll ? treeData.expandAllKeys : [startingKey]
        }
      >
        {({ items }) => {
          const filteredItems = search?.keyword
            ? items?.filter(({ description, label, level }) => {
                const searchString = search.keyword?.toLocaleLowerCase()?.toLocaleLowerCase();
                const stringToMatch = `${label ?? ''}${description ?? ''}`?.toLocaleLowerCase();

                if (level > filterLevelFrom) {
                  const match = stringToMatch?.includes(searchString);

                  if (search.exclude) {
                    return !match;
                  }

                  return match;
                }

                return true;
              })
            : items;

          return (
            <>
              {isInputVisible && !flags.lineage_list_expand_all && (
                <Box compDisplay="flex" flexGrow={1} my={1} px={2} spacing={2}>
                  <Box alignItems="center" compDisplay="flex" flexShrink={0}>
                    <Icon name="filter-filled" size="18px" />
                    <StyledDropdown text="Type">
                      <Dropdown.Menu>
                        {Object.entries(options)
                          ?.filter((item) => !['autoClose', 'showFieldUsage'].includes(item[0]))
                          ?.map((item) => {
                            const filterName = item[0];
                            const filter = item?.[1];
                            const icon =
                              filterByTypeIconsMap[filterName as keyof typeof filterByTypeIconsMap];

                            return (
                              <Dropdown.Item
                                key={filterName}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <InputLabel compHeight="100%" compWidth="100%" fontSize="body1">
                                  <Checkbox
                                    checked={item[1]?.value}
                                    onClick={() => {
                                      setOptions((prev) => ({
                                        ...prev,
                                        [filterName]: { ...filter, value: !filter?.value },
                                      }));
                                    }}
                                  />
                                  {icon}
                                  {filter?.label}
                                </InputLabel>
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </StyledDropdown>
                  </Box>
                  <Input
                    ref={searchInputRef as any}
                    endIcon={
                      <InputLabel
                        alignItems="center"
                        compDisplay="flex"
                        fontSize="body1"
                        verticalAlign="middle"
                      >
                        <Checkbox
                          checked={search.exclude}
                          onChange={(e) =>
                            setSearch({ ...search, exclude: Boolean(e.target.checked) })
                          }
                        />
                        Exclude
                      </InputLabel>
                    }
                    onChange={({ target }) =>
                      setSearchDebounced({ ...search, keyword: target.value })
                    }
                    placeholder="Filter by text"
                    type="text"
                  />
                </Box>
              )}
              {flags.lineage_list_expand_all && (
                <Box
                  alignItems="center"
                  compDisplay="flex"
                  justifyContent="space-between"
                  mb={0.75}
                  mt={2.25}
                >
                  <Input
                    aria-label="search"
                    compWidth="396px"
                    endIcon={
                      <InputLabel
                        alignItems="center"
                        compDisplay="flex"
                        fontSize="body1"
                        verticalAlign="middle"
                      >
                        <Checkbox
                          checked={search.exclude}
                          onChange={(e) =>
                            setSearch({ ...search, exclude: Boolean(e.target.checked) })
                          }
                        />
                        Exclude
                      </InputLabel>
                    }
                    onChange={({ target }) =>
                      setSearchDebounced({ ...search, keyword: target.value })
                    }
                    placeholder="Search"
                    startIcon={<Icon color="gray.800" name="search" size="20px" />}
                  />
                  {onExportCsvClick && (
                    <ExportToCsvButton isLoading={isLoadingExportCsv} onClick={onExportCsvClick} />
                  )}
                </Box>
              )}
              <SortByPanel>
                <Box
                  compDisplay="flex "
                  compWidth="100%"
                  py={flags.lineage_list_expand_all ? 1.5 : 0}
                >
                  {treeColumnsConfig[0] !== null && (
                    <Box
                      gap={1}
                      pl={flags.lineage_list_expand_all ? 2 : 0}
                      pr={2}
                      {...treeColumnsConfig[0]}
                    >
                      {!flags.lineage_list_expand_all && (
                        <Box backgroundColor="#f2f2f2" px={0.5} py={1}>
                          <IconButton onClick={handleShowInputToggle} variant="clear">
                            <Icon name="search" size="16px" />
                          </IconButton>
                        </Box>
                      )}
                      <OrderByButton
                        onClick={(orderBy) => {
                          updateSearch('name', orderBy);
                        }}
                        orderBy={search.sortBy === 'name' ? search.orderBy : 'default'}
                      >
                        Name {wrapString((filteredItems?.length ?? 1) - 1)}
                      </OrderByButton>
                    </Box>
                  )}
                  {treeColumnsConfig[1] !== null && (
                    <Box {...treeColumnsConfig[1]}>
                      <OrderByButton
                        onClick={(orderBy) => {
                          updateSearch('description', orderBy);
                        }}
                        orderBy={search.sortBy === 'description' ? search.orderBy : 'default'}
                      >
                        Description
                      </OrderByButton>
                    </Box>
                  )}
                  {treeColumnsConfig[2] !== null && (
                    <Box compDisplay="flex" {...treeColumnsConfig[2]}>
                      <OrderByButton
                        onClick={(orderBy) => {
                          updateSearch('usage', orderBy);
                        }}
                        orderBy={search.sortBy === 'usage' ? search.orderBy : 'default'}
                      >
                        Usage
                      </OrderByButton>
                    </Box>
                  )}
                  {treeColumnsConfig[3] !== null && (
                    <Box {...treeColumnsConfig[3]}>
                      <OrderByButton
                        onClick={(orderBy) => {
                          updateSearch('popularity', orderBy);
                        }}
                        orderBy={search.sortBy === 'popularity' ? search.orderBy : 'default'}
                      >
                        Popularity
                      </OrderByButton>
                    </Box>
                  )}
                </Box>
              </SortByPanel>
              <Box as="ul">
                {filteredItems?.map((item) => (
                  <SidebarTreeItem
                    {...item}
                    key={item.key}
                    direction={direction}
                    disableExpandAll={disableExpandAll()}
                    enableHighlight={!search.exclude}
                    expandAllState={expandAllState}
                    isExpandingAll={isExpandingAll}
                    isLoading={isLoading}
                    loadLineage={loadLineage}
                    onClick={() => {
                      if (zoomOnItemClick) {
                        setZoomToTableId(item.tableId);
                      }
                      onItemClick?.(item);

                      setClickedGuid((prev) => ({ ...prev, [item.id]: item.level }));
                    }}
                    onExpandAllClick={handleExpandAllClick}
                    onSqlButtonClick={onSqlButtonClick}
                    searchKeyword={search.keyword}
                    showExpandAll={showExpandAll && item.level === 0}
                    treeColumnsConfig={treeColumnsConfig}
                  />
                ))}
              </Box>
            </>
          );
        }}
      </TreeMenu>
    </TreeContainer>
  );
};

export default React.memo(SidebarTree);
