import React, { useEffect } from 'react';

import { PiiRuleModel } from '@api/piiRules/PiiRuleModel';
import DocsButton from '@components/Button/BIButtons/DocsButton';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import { FormHorizontalLabelGrid } from '@components/Form/Form.styles';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalFooter, ModalHeader } from '@components/UI/Modal';
import Select from '@components/UI/Select.v1/Select';
import { Option, SelectValue } from '@components/UI/Select.v1/types';

import useCreatePiiRuleForm from './useCreatePiiRuleForm';

interface CreatePiiRuleModalProps {
  onClose: () => void;
  pii?: PiiRuleModel;
}

interface FormPayload {
  name: string;
  regex: string;
  type: SelectValue;
}

const PII_TYPE_DEFAULT_VALUE: Option = { text: 'SSN', value: 'ssn' };

const PII_TYPE_OPTIONS: Option[] = [
  { text: 'Address', value: 'address' },
  { text: 'Banking', value: 'banking' },
  { text: 'Birthdate', value: 'birthdate' },
  { text: 'Document', value: 'document' },
  { text: 'Email', value: 'email' },
  { text: 'Face Photo', value: 'face_photo' },
  { text: 'Gender', value: 'gender' },
  { text: 'Marital Status', value: 'marital_status' },
  { text: 'Nationality', value: 'nationality' },
  { text: 'Online Account', value: 'online_account' },
  { text: 'Online Address', value: 'online_address' },
  { text: 'Password', value: 'password' },
  { text: 'Person', value: 'person' },
  { text: 'Phone', value: 'phone' },
  { text: 'Salary', value: 'salary' },
  { text: 'Username', value: 'username' },
  PII_TYPE_DEFAULT_VALUE,
];

const CreatePiiRuleModal: React.FC<CreatePiiRuleModalProps> = ({ onClose, pii }) => {
  const { patchQuery, postQuery } = useCreatePiiRuleForm({ onSuccess: onClose, pii });
  const isEditing = Boolean(pii);
  const updateQuery = isEditing ? patchQuery : postQuery;

  const onSubmit = (payload: FormPayload) => {
    const [piiType] = payload.type ?? [];

    const formattedPayload = {
      ...payload,
      type: String(piiType?.value),
    };
    if (isEditing) {
      let finalPayload = {};
      if (formattedPayload.name !== pii?.name) {
        finalPayload = { name: formattedPayload.name };
      }
      if (formattedPayload.regex !== pii?.regex) {
        finalPayload = { ...finalPayload, regex: formattedPayload.regex };
      }
      if (formattedPayload.type !== pii?.type) {
        finalPayload = { ...finalPayload, type: formattedPayload.type };
      }
      patchQuery.mutate(finalPayload);
    } else {
      postQuery.mutate(formattedPayload);
    }
  };

  const { handleChange, handleSubmit, setValues, values } = useForm<FormPayload>({
    initialValues: { name: '', regex: '', type: [PII_TYPE_DEFAULT_VALUE] },
    onSubmit,
  });

  const title = isEditing ? 'Edit PII Rule' : 'Create PII Rule';
  const isValid = Boolean(values.name) && Boolean(values.regex);

  useEffect(() => {
    const piiTypeValue =
      PII_TYPE_OPTIONS.find((option) => option.value === pii?.type) ?? PII_TYPE_DEFAULT_VALUE;
    setValues({ name: pii?.name ?? '', regex: pii?.regex ?? '', type: [piiTypeValue] });
  }, [pii, setValues]);

  return (
    <Modal onClose={onClose}>
      <ModalHeader
        onClose={onClose}
        title={
          <>
            {title}
            <DocsButton
              href="https://docs.selectstar.com/data-management/automated-pii-detection-beta"
              rel="noopener noreferrer"
              target="_blank"
            />
          </>
        }
      />
      <Form isLoading={updateQuery.isLoading} onSubmit={handleSubmit}>
        <FormHorizontalLabelGrid alignItems="baseline" gridTemplateColumns="auto 1fr">
          <InputLabel compDisplay="contents" variantColor="modalForm">
            Name
            <Input
              error={updateQuery.error?.data?.name}
              helperText={updateQuery.error?.data?.name}
              name="name"
              onChange={handleChange}
              placeholder="Rule Name"
              value={values.name}
            />
          </InputLabel>
          <InputLabel as="div" compDisplay="contents" variantColor="modalForm">
            PII Type
            <Select
              error={Boolean(updateQuery.error?.data?.type)}
              helperText={updateQuery.error?.data?.type}
              onChange={(value) => {
                setValues((current) => ({ ...current, type: value }));
              }}
              options={PII_TYPE_OPTIONS}
              value={values.type}
            />
          </InputLabel>
          <InputLabel alignItems="center" compDisplay="contents" variantColor="modalForm">
            Rule
            <Input
              error={updateQuery.error?.data?.regex}
              helperText={updateQuery.error?.data?.regex ?? 'Only regex-based rules are supported'}
              name="regex"
              onChange={handleChange}
              placeholder="ssn|national_id"
              value={values.regex}
            />
          </InputLabel>
        </FormHorizontalLabelGrid>
        <ModalFooter>
          <Button onClick={onClose} type="button" variant="outlined">
            Cancel
          </Button>
          <Button disabled={!isValid} type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreatePiiRuleModal;
