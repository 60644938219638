import React from 'react';
import { dataSources as DATASOURCES_CONFIG } from '@configs/dataSources/config';
import { Redirect, useParams } from 'react-router-dom';

import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import { GridContainer } from '@components/Grid';
import CreateDbtProjectModal from '@components/Modal/CreateDbtProjectModal';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';
import type { PageHeaderBarProps } from '@components/PageHeaderBar';
import PageHeaderBar from '@components/PageHeaderBar';
import TabbedPageStyled from '@components/TabbedPageStyled';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import { DataSourceModel } from '@models/DataSourceModel';
import MetadataDecorator from '@utils/MetadataDecorator';

import AdminRecentQueriesTab from './Tabs/AdminRecentQueriesTab';
import BIConnections from './Tabs/BIConnectionSetupDS/BIConnections';
import Connections from './Tabs/Connections/Connections';
import DbtDocsSync from './Tabs/DbtDocsSync/DbtDocsSync';
import DbtProjects from './Tabs/DbtProjects/DbtProjects';
import DbtSetupTags from './Tabs/DbtSetupTags/DbtSetupTags';
import DSUsersTab from './Tabs/DSUsersTab';
import SnowflakeTagSync from './Tabs/SnowflakeTagSync';

const AdminDataSourcePage: React.FC = () => {
  const { dsType, guid } = useParams<{ dsType: string; guid: string }>();
  const { dataSources, isOrgAdmin, settings } = useUserContext();
  const { MODAL_IDS, checkModalOpened, getModalContent, openModal } = useModal();
  const { isEditable, isFetching, permissions } = useObjectPermissionsContext({ id: guid });
  const useRbac = settings?.useRbac;

  const permission = permissions[guid];

  if (!guid) return null;
  if (!dsType) return null;
  if (useRbac && permission !== undefined && !isEditable && !isOrgAdmin)
    return <Redirect to="/admin" />;

  if (isFetching) return <CircularLoader centered cover />;

  const dataSource: DataSourceModel | undefined = dataSources ? dataSources[guid] : undefined;

  const tabComponentProps = {
    dsGuid: guid,
    dsType,
    stickyHeader: true,
  };

  const usersTab = {
    // eslint-disable-next-line react/no-unstable-nested-components
    component: () => <DSUsersTab {...tabComponentProps} />,
    default: true,
    label: 'Users',
    route: '/users',
  };

  const tabContentMap: Record<
    string,
    { tabs: TabRoutesConfig[] } & Pick<PageHeaderBarProps, 'rightEdgeEl'>
  > = {
    bigquery: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
      ],
    },
    data_studio: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <BIConnections {...tabComponentProps} />,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
    databricks: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
      ],
    },
    dbt: {
      rightEdgeEl: settings?.useDbtMultiProject ? (
        <Button
          onClick={() => {
            openModal(MODAL_IDS.createDbtProject, undefined);
          }}
          startIcon={<Icon name="plus" size="16px" />}
          variant="outlined"
        >
          Add a New Project
        </Button>
      ) : null,
      tabs: [
        usersTab,
        ...(settings?.useDbtMultiProject
          ? [
              {
                // eslint-disable-next-line react/no-unstable-nested-components
                component: () => <DbtProjects {...tabComponentProps} />,
                default: true,
                label: 'Projects',
                route: '/projects',
              },
            ]
          : []),
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <DbtSetupTags {...tabComponentProps} />,
          label: 'Tags',
          route: '/tags',
        },
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <DbtDocsSync {...tabComponentProps} />,
          label: 'docs Sync',
          route: '/docs-sync',
        },
      ],
    },
    default: {
      tabs: [usersTab],
    },
    metabase: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <BIConnections {...tabComponentProps} />,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
    mode: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <Connections {...tabComponentProps} />,
          contentOffset: 1,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
    [DATASOURCES_CONFIG.mssql.value]: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
      ],
    },
    [DATASOURCES_CONFIG.mysql.value]: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
      ],
    },
    [DATASOURCES_CONFIG.oracle.value]: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
      ],
    },
    periscope: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <Connections {...tabComponentProps} />,
          contentOffset: 1,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
    postgres: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
      ],
    },
    power_bi: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <BIConnections {...tabComponentProps} />,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
    quicksight: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <BIConnections {...tabComponentProps} />,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
    redshift: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
      ],
    },
    sigma: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <Connections {...tabComponentProps} />,
          contentOffset: 1,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
    snowflake: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <AdminRecentQueriesTab {...tabComponentProps} />,
          label: 'Recent Queries',
          route: '/recent-queries',
        },
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <SnowflakeTagSync {...tabComponentProps} />,
          label: 'Snowflake Tag Sync',
          route: '/tag-sync',
        },
      ],
    },
    tableau: {
      tabs: [
        usersTab,
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => <BIConnections {...tabComponentProps} />,
          label: 'Connections',
          route: '/connections',
        },
      ],
    },
  };

  const routerConfig = tabContentMap?.[dsType] ?? tabContentMap.default;
  const dsIcon = <Icon name={dataSource?.dataTypes?.icons.dataSource!} />;
  const confirmationModalProps = getModalContent(MODAL_IDS.deleteConfirmation);

  return (
    <>
      <MetadataDecorator title={dataSource?.name} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <TabbedPageStyled>
          <PageHeaderBar
            icon={dsIcon}
            rightEdgeEl={routerConfig.rightEdgeEl}
            sticky
            title={dataSource?.name}
          />
          <TabsRouter config={routerConfig.tabs} sticky />
        </TabbedPageStyled>
      </GridContainer>
      {checkModalOpened(MODAL_IDS.createDbtProject) && dataSource && (
        <CreateDbtProjectModal dataSource={dataSource} />
      )}
      {checkModalOpened(MODAL_IDS.deleteConfirmation) && (
        <DeleteConfirmationModal {...confirmationModalProps} />
      )}
    </>
  );
};

export default AdminDataSourcePage;
